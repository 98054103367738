import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import SectionSliderCollections from "components/SectionSliderLargeProduct";
import ProductCard from "components/ProductCard";
import { Product, TOYS_PRODUCTS } from "data/data";
import SidebarFilters from "./SidebarFilters";
import ProductsApi from "Apis/ProductsApi";
import ApiKeyEnc from "Apis/ApiKeyEnc";
import Spinner from "react-bootstrap/Spinner";

export interface PageCollection2Props {
  className?: string;
}

const PageCollection2: FC<PageCollection2Props> = ({ className = "" }) => {
  const [filteredProducts, setFilteredProducts] = useState<any>();
  const [products, setProducts] = useState<any>();
  const [loading, setLoading] = useState(false);

  console.log("filtered", filteredProducts);

  const getAllProducts = async () => {
    setLoading(true); // Show loading spinner
    try {
      const response = await ProductsApi.getAllProducts();
      if (response.success) {
        setProducts(response.data.data.products);
        setFilteredProducts(response.data.data.products);
      }
    } catch (error) {
      console.error("Failed to fetch products:", error);
    } finally {
      setLoading(false); // Hide loading spinner
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <div
      className={`nc-PageCollection2 ${className}`}
      data-nc-id="PageCollection2"
    >
      <Helmet>
        <title>Categories | Ood Blue</title>
      </Helmet>

      <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
        <div className="space-y-10 lg:space-y-14">
          {/* HEADING */}
          <div className="max-w-screen-sm">
            <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">
              Kids collection
            </h2>
            <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
              Discover our Kids Collection: A handpicked selection of fun,
              stylish, and educational products designed to delight and inspire
              children of all ages.
            </span>
          </div>

          <hr className="border-slate-200 dark:border-slate-700" />

          {/* MAIN CONTENT */}
          <main>
            {loading ? (
              // Show spinner while loading
              <div className="d-flex justify-content-center my-5">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <div className="flex flex-col lg:flex-row">
                {/* SIDEBAR */}
                <div className="lg:w-1/3 xl:w-1/4 pr-4">
                  <SidebarFilters
                    products={products}
                    setFilteredProducts={setFilteredProducts}
                  />
                </div>
                <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mx-4 border-t lg:border-t-0"></div>
                {/* PRODUCT GRID */}
                <div className="flex-1">
                  <div className="flex-1 grid sm:grid-cols-2 xl:grid-cols-3 gap-x-8 gap-y-10">
                    {filteredProducts?.map((item: any) => (
                      <ProductCard data={item} key={item.id} />
                    ))}
                  </div>
                </div>
              </div>
            )}
          </main>
        </div>

        {/* === SECTION 5 === */}
        <hr className="border-slate-200 dark:border-slate-700" />
        <SectionSliderCollections />
        {/* <hr className="border-slate-200 dark:border-slate-700" /> */}
      </div>
    </div>
  );
};

export default PageCollection2;
